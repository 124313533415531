import React, {useEffect, useState} from 'react';
import api from '../Services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from 'react-router-dom';
import LogoColorida from '../Images/Ativo4.svg'
import bgImg from '../Images/Login/wordsSkillsAmerica 1.png'
import brazilImg from '../Images/Countries/Brazil.png'
import usaImg from '../Images/Countries/United States of America.png'
import spainImg from '../Images/Countries/Spain.png'
import CustomSelector from "../Components/customSelector/customSelector";
import Modal from "react-bootstrap/Modal";

const pageTexts = {
    password: {
        "es": "Contraseña",
        "pt-br": "Senha",
        "en": "Password"
    },
    errorMsg: {
        "es": "No se pudo finalizar el inicio de sesión. Verifica tus credenciales o vuelve a intentarlo más tarde",
        "pt-br": "Não foi possível concluir o login. Verifique suas credenciais ou tente novamente mais tarde",
        "en": "Could not finish the login. Verify your credentials or try again later"
    },
    btn: {
        "es": 'Acceso',
        "pt-br": "Entrar",
        "en": "Login"
    },
    forgotPassword: {
        "es": 'Has olvidado tu contraseña',
        "pt-br": "Esqueceu sua senha",
        "en": "Forgot password"
    },
    haveRecoveryCode: {
        "es": 'Tengo un código de recuperación de contraseña',
        "pt-br": "Tenho um código de recuperação de senha",
        "en": "I have a password recovery code"
    },
    sendBtn: {
        "es": "Enviar email",
        "pt-br": "Enviar email",
        "en": "Send email"
    },
    closeBtn: {
        "es": "Cerrar",
        "pt-br": "Fechar",
        "en": "Close"
    },
    emailSended: {
        "es": "Se ha enviado un email de recuperación.",
        "pt-br": "Um email de recuperação foi enviado",
        "en": "A recovery email has been sent"
    },
    emailErrorMessage: {
        "es": "No se pudo enviar el email. Vuelva a intentarlo más tarde",
        "pt-br": "O email não pode ser enviado, tente novamente mais tarde",
        "en": "The email could not be sent. Try again later"
    }
}

function LoginPage() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [errorMsg, setErrorMsg] = useState('d-none')
    const [emailErrorMsg, setEmailErrorMsg] = useState('d-none')
    const navigate = useNavigate();
    const [language, setLanguage] = useState('')
    const [currentLanguage, setCurrentLanguage] = useState('');
    const [show, setShow] = useState(false);
    const [passRecoverEmail, setPassRecoverEmail] = useState("");
    const [emailSended, setEmailSended] = useState(false);

    const Login = (e) => {
        e.preventDefault();

        api.post('auth/login', {
            email: email,
            senha: password
        }).then(x => {
            if (x.status === 200) {
                window.localStorage.setItem('token', x.data.token)
            }
        })
            .then(x => {
                navigate("/home")
            })
            .catch(x => {
                setPassword("")
                setErrorMsg('')
            })
    }

    const SendRecoveryEmail = (e) => {
        e.preventDefault();

        api.post('auth/forgot-password/', {
            email: passRecoverEmail,
        })
            .then(x => {
                console.log(x)
                setEmailSended(true);
                setShow(false);
                setEmailErrorMsg('d-none')
            })
            .catch(x => {
                setShow(false);
                setPassRecoverEmail("");
                setEmailErrorMsg('')
            })
    }

    useEffect(() => {
        if (navigator.language.split("-")[0] === "en" || navigator.language.split("-")[0] === "es") {
            setCurrentLanguage(navigator.language.split("-")[0].toLowerCase());
            setLanguage(navigator.language.split("-")[0].toLowerCase());
            localStorage.setItem("language", navigator.language.split("-")[0].toLowerCase());
        } else {
            setCurrentLanguage(navigator.language.toLowerCase());
            setLanguage(navigator.language.toLowerCase());
            localStorage.setItem("language", navigator.language.toLowerCase());
        }
    }, [])
    useEffect(() => {
        if (language !== "") {
            localStorage.setItem("language", language)
            setCurrentLanguage(language)
        } else {
            setLanguage(localStorage.getItem("language"))
            setCurrentLanguage(localStorage.getItem("language"))
        }
    }, [language])

    useEffect(() => {
        if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== undefined) {
            navigate("/home")
        }
    }, [])

    return (
        <div>
            <div className="position-absolute h-100 pt-5" style={{
                zIndex: 2,
                right: 0,
                width: window.innerWidth >= 992 ? "40%" : window.innerWidth >= 768 ? "60%" : "100%"
            }}>
                <form onSubmit={(e) => Login(e)} className="d-flex flex-column align-items-center w-100 h-100 pt-5">
                    <img className="mb-5" src={LogoColorida} style={{width: "40%"}} alt=""/>
                    <div className="mb-4 position-relative" style={{width: window.innerWidth >= 576 ? "400px" : "75%"}}>
                        <CustomSelector label={"Idioma/Language"}
                                        items={[
                                            {label: "Português", img: brazilImg, value: "pt-br"},
                                            {label: "English", img: usaImg, value: "en"},
                                            {label: "Español", img: spainImg, value: "es"}
                                        ]}
                                        stateCallback={setLanguage}
                                        fontSize={"4"}
                                        initialValue={localStorage.getItem("language") ? localStorage.getItem("language") : ""}></CustomSelector>
                    </div>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email"
                           className="form-control border-black w-75 mb-4 pt-3 pb-3 fs-5" id="exampleInputEmail1"
                           aria-describedby="emailHelp"
                           placeholder="E-mail:"/>
                    <input value={password} onChange={(e) => setPassword(e.target.value)} type="password"
                           className="form-control mb-5 border-black w-75 pt-3 pb-3 fs-5" id="exampleInputPassword1"
                           placeholder={pageTexts.password[localStorage.getItem("language")] ? pageTexts.password[localStorage.getItem("language")] + ": " : "Senha: "}/>
                    <button type="submit"
                            className="btn btn-dark w-75 pt-3 pb-3 mb-1">{pageTexts.btn[localStorage.getItem("language")] ? pageTexts.btn[localStorage.getItem("language")] : "Enviar"}</button>
                    {
                        !emailSended ?
                            <button onClick={(ev) => {
                                setShow(true)
                            }} type="button"
                                    className="mb-1 bg-transparent border-0 link-info">{pageTexts.forgotPassword[localStorage.getItem("language")] ? pageTexts.forgotPassword[localStorage.getItem("language")] : pageTexts.forgotPassword["pt-br"]}</button>
                            :
                            <span
                                className="mb-1 bg-transparent border-0 link-info">{pageTexts.emailSended[localStorage.getItem("language")] ? pageTexts.emailSended[localStorage.getItem("language")] : pageTexts.emailSended["pt-br"]}</span>
                    }
                    <button onClick={(ev) => {
                        navigate("/validate-recover-code")
                    }} type="button"
                            className="mb-4 bg-transparent border-0 link-info">{pageTexts.haveRecoveryCode[localStorage.getItem("language")] ? pageTexts.haveRecoveryCode[localStorage.getItem("language")] : pageTexts.haveRecoveryCode["pt-br"]}</button>
                    <span
                        className={"text-danger fs-6 text-center " + errorMsg}>{pageTexts.errorMsg[localStorage.getItem("language")] ? pageTexts.errorMsg[localStorage.getItem("language")] : "Não foi possível realizar o Login. Se as credenciais forem válidas, tente novamente mais tarde."}</span>
                    <span
                        className={"text-danger fs-6 text-center " + emailErrorMsg}>{pageTexts.emailErrorMessage[localStorage.getItem("language")] ? pageTexts.emailErrorMessage[localStorage.getItem("language")] : pageTexts.emailErrorMessage["pt-br"]}</span>
                </form>
            </div>
            <div className="position-absolute d-flex w-100 h-100 top-0 overflow-hidden" style={{zIndex: 1}}>
                <div className="position-absolute" style={{
                    right: window.innerWidth >= 992 ? "40%" : window.innerWidth >= 768 ? "60%" : "100%",
                    zIndex: 2,
                    borderTop: "transparent solid 100vh",
                    borderLeft: "transparent solid",
                    borderRight: "white solid 12vw",
                    borderBottom: "white solid 100vh"
                }}>

                </div>
                <div className="position-relative"
                     style={{width: window.innerWidth >= 992 ? "60%" : window.innerWidth >= 768 ? "40%" : "0%"}}>
                    <div className="w-100 h-100 bg-black position-absolute opacity-50"></div>
                    <img className="w-100 h-100 object-fit-cover" src={bgImg} alt=""/>
                </div>
            </div>

            <Modal show={show} onHide={x => setShow(false)}>
                <Modal.Header>
                    {pageTexts.forgotPassword[localStorage.getItem("language")] ? pageTexts.forgotPassword[localStorage.getItem("language")] : pageTexts.forgotPassword["pt-br"]}
                </Modal.Header>
                <Modal.Body>
                    <form className='form d-flex flex-column'>
                        <label>
                            Email:
                            <input value={passRecoverEmail} onChange={(e) => setPassRecoverEmail(e.target.value)}
                                   type='email' required className='form-control'></input>
                        </label>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type='text' onClick={x => setShow(false)}
                            className='btn btn-danger'>{pageTexts.closeBtn[localStorage.getItem("language")] ? pageTexts.closeBtn[localStorage.getItem("language")] : pageTexts.closeBtn["pt-br"]}</button>
                    <button type='submit' onClick={(ev) => SendRecoveryEmail(ev)}
                            className='btn btn-success'>{pageTexts.sendBtn[localStorage.getItem("language")] ? pageTexts.sendBtn[localStorage.getItem("language")] : pageTexts.sendBtn["pt-br"]}</button>
                </Modal.Footer>
            </Modal>


        </div>
    );
}

export default LoginPage;   