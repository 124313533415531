import React, {useEffect, useState} from 'react';
import api from '../Services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import LogoColorida from '../Images/Ativo4.svg'
import bgImg from '../Images/Login/wordsSkillsAmerica 1.png'
import brazilImg from '../Images/Countries/Brazil.png'
import usaImg from '../Images/Countries/United States of America.png'
import spainImg from '../Images/Countries/Spain.png'
import CustomSelector from "../Components/customSelector/customSelector";
import Modal from "react-bootstrap/Modal";

const pageTexts = {
    code: {
        "es": "Código",
        "pt-br": "Código",
        "en": "Code"
    },
    btn: {
        "es": "Avance",
        "pt-br": "Avançar",
        "en": "Next"
    },
    errorMsg: {
        "es": "Código de recuperación de contraseña no válido. Intentar otra vez...",
        "pt-br": "Código de recuperação de senha inválido. Tente novamente...",
        "en": "Invalid password recovery code. Try again..."
    }
}

function RecoverPasswordPage() {

    const [recoveryCode, setRecoveryCode] = useState('')

    const [errorMsg, setErrorMsg] = useState('d-none')
    const navigate = useNavigate();
    const [language, setLanguage] = useState('')
    const [currentLanguage, setCurrentLanguage] = useState('');

    const validateCode = (e) => {
        e.preventDefault()
        api.get('auth/validate-password-recovery-code/' + recoveryCode)
            .then(x => {
                navigate("/recover-password?code=" + recoveryCode)
            })
            .catch(x => {
                setErrorMsg('');
                setRecoveryCode("")
            })
    }

    useEffect(() => {
        if (navigator.language.split("-")[0] === "en" || navigator.language.split("-")[0] === "es") {
            setCurrentLanguage(navigator.language.split("-")[0].toLowerCase());
            setLanguage(navigator.language.split("-")[0].toLowerCase());
            localStorage.setItem("language", navigator.language.split("-")[0].toLowerCase());
        } else {
            setCurrentLanguage(navigator.language.toLowerCase());
            setLanguage(navigator.language.toLowerCase());
            localStorage.setItem("language", navigator.language.toLowerCase());
        }
    }, [])
    useEffect(() => {
        if (language !== "") {
            localStorage.setItem("language", language)
            setCurrentLanguage(language)
        } else {
            setLanguage(localStorage.getItem("language"))
            setCurrentLanguage(localStorage.getItem("language"))
        }
    }, [language])

    useEffect(() => {
        if (localStorage.getItem("token") !== null && localStorage.getItem("token") !== undefined) {
            navigate("/home")
        }
    }, [])

    return (
        <div>
            <div className="position-absolute h-100 pt-5" style={{
                zIndex: 2,
                right: 0,
                width: window.innerWidth >= 992 ? "40%" : window.innerWidth >= 768 ? "60%" : "100%"
            }}>
                <form onSubmit={(e) => validateCode(e)}
                      className="d-flex flex-column align-items-center w-100 h-100 pt-5">
                    <img className="mb-5" src={LogoColorida} style={{width: "40%"}} alt=""/>
                    <div className="mb-4 position-relative" style={{width: window.innerWidth >= 576 ? "400px" : "75%"}}>
                        <CustomSelector label={"Idioma/Language"}
                                        items={[
                                            {label: "Português", img: brazilImg, value: "pt-br"},
                                            {label: "English", img: usaImg, value: "en"},
                                            {label: "Español", img: spainImg, value: "es"}
                                        ]}
                                        stateCallback={setLanguage}
                                        fontSize={"4"}
                                        initialValue={localStorage.getItem("language") ? localStorage.getItem("language") : ""}></CustomSelector>
                    </div>
                    <input value={recoveryCode} onChange={(e) => setRecoveryCode(e.target.value)} type="text"
                           className="form-control mb-5 border-black w-75 pt-3 pb-3 fs-5" id="exampleInputPassword1"
                           placeholder={pageTexts.code[localStorage.getItem("language")] ? pageTexts.code[localStorage.getItem("language")] + ": " : "Código: "}/>
                    <button type="submit"
                            className="btn btn-dark w-75 pt-3 pb-3 mb-4">{pageTexts.btn[localStorage.getItem("language")] ? pageTexts.btn[localStorage.getItem("language")] : "Avançar"}</button>
                    <span
                        className={"text-danger fs-6 text-center " + errorMsg}>{pageTexts.errorMsg[localStorage.getItem("language")] ? pageTexts.errorMsg[localStorage.getItem("language")] : pageTexts.errorMsg["pt-br"]}</span>

                </form>
            </div>
            <div className="position-absolute d-flex w-100 h-100 top-0 overflow-hidden" style={{zIndex: 1}}>
                <div className="position-absolute" style={{
                    right: window.innerWidth >= 992 ? "40%" : window.innerWidth >= 768 ? "60%" : "100%",
                    zIndex: 2,
                    borderTop: "transparent solid 100vh",
                    borderLeft: "transparent solid",
                    borderRight: "white solid 12vw",
                    borderBottom: "white solid 100vh"
                }}>

                </div>
                <div className="position-relative"
                     style={{width: window.innerWidth >= 992 ? "60%" : window.innerWidth >= 768 ? "40%" : "0%"}}>
                    <div className="w-100 h-100 bg-black position-absolute opacity-50"></div>
                    <img className="w-100 h-100 object-fit-cover" src={bgImg} alt=""/>
                </div>
            </div>


        </div>
    );
}

export default RecoverPasswordPage;