import React, {useEffect, useState} from "react";
import {Modal, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";


import logo from "../Images/Ativo1.svg"
import CustomSelector from "./customSelector/customSelector";
import brazilImg from "../Images/Countries/Brazil.png";
import usaImg from "../Images/Countries/United States of America.png";
import spainImg from "../Images/Countries/Spain.png";
import api from "../Services/api";

const pageTexts = {
    active: {
        "es": "Elección actual",
        "pt-br": "Eleição atual",
        "en": "Current ellection"
    },
    finished: {
        "es": "Elecciones terminadas",
        "pt-br": "Eleições finalizadas",
        "en": "Finished ellections"
    },
    btn: {
        "es": 'Cerrar sesión',
        "pt-br": "Sair",
        "en": "Logout"
    },
    changePassword: {
        "es": 'Cambiar la contraseña',
        "pt-br": "Alterar senha",
        "en": "Change password"
    },
    changePasswordInformative: {
        "es": 'Recibirás instrucciones en tu email, ¿quieres continuar?',
        "pt-br": "Você receberá instruções em seu email, deseja prosseguir?",
        "en": "You'll receive instructions in your email, do you want to proceed?"
    },
    btnModal: {
        "es": "Sí",
        "pt-br": "Sim",
        "en": "Yes"
    },
    cancel: {
        "es": "Cancelar",
        "pt-br": "Cancelar",
        "en": "Cancel"
    },
    emailErrorMessage: {
        "es": "No se pudo enviar el email. Vuelva a intentarlo más tarde",
        "pt-br": "O email não pode ser enviado, tente novamente mais tarde",
        "en": "The email could not be sent. Try again later"
    }
}

function NavBarMine() {
    const [show, setShow] = useState(false);
    const [language, setLanguage] = useState("");
    const [currentLanguage, setCurrentLanguage] = useState("")
    const navigate = useNavigate();

    function LogOut() {
        api.delete("auth/logout", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(x => {
            window.localStorage.clear();
            navigate("/")
        }).catch(x => {
            window.localStorage.clear();
            navigate("/")
        })
    }

    function sendChangePass(ev) {
        ev.preventDefault()
        api.post("auth/request-password-change", {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then(x => {
            setShow(false)
        }).catch(x => {
            setShow(false)
            window.alert(pageTexts.emailErrorMessage[currentLanguage] ? pageTexts.emailErrorMessage[currentLanguage] : pageTexts.emailErrorMessage["pt-br"])
        })
    }

    useEffect(() => {
        if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined) {
            window.localStorage.clear();
            navigate("/")
        }
    }, [])

    useEffect(() => {
        if (language !== "") {
            localStorage.setItem("language", language)
            setCurrentLanguage(language)
        } else {
            setLanguage(localStorage.getItem("language"))
            setCurrentLanguage(localStorage.getItem("language"))
        }
    }, [language])

    return (
        window.innerWidth >= 768 ?
            <Navbar className="bg-dark">
                <div className='container'>
                    <Navbar.Brand><img src={logo} className="img-fluid imgLogoHeader"/></Navbar.Brand>

                    <Nav className='ms-auto'>
                        <Nav.Link className={"text-center d-flex align-items-center justify-content-center"}><Link
                            className={`${window.location.pathname == "/home" ? 'text-info' : 'text-light'}`}
                            to='/home'>{pageTexts.active[currentLanguage] ? pageTexts.active[currentLanguage] : pageTexts.active["pt-br"]}</Link></Nav.Link>
                        <Nav.Link className={"text-center d-flex align-items-center justify-content-center"}><Link
                            className={`${window.location.pathname == "/fechadas" ? 'text-info' : 'text-light'}`}
                            to='/fechadas'>{pageTexts.finished[currentLanguage] ? pageTexts.finished[currentLanguage] : pageTexts.finished["pt-br"]}</Link></Nav.Link>
                        <Nav.Link className={"text-center d-flex align-items-center justify-content-center text-light"}
                                  onClick={x => {
                                      setShow(true)
                                  }}>{pageTexts.changePassword[currentLanguage] ? pageTexts.changePassword[currentLanguage] : pageTexts.changePassword["pt-br"]}</Nav.Link>
                        <div className={"position-relative"} style={{width: "200px"}}>
                            <CustomSelector label={"Idioma/Language"}
                                            light={true}
                                            items={[
                                                {label: "Português", img: brazilImg, value: "pt-br"},
                                                {label: "English", img: usaImg, value: "en"},
                                                {label: "Español", img: spainImg, value: "es"}
                                            ]}
                                            stateCallback={setLanguage}
                                            initialValue={localStorage.getItem("language") ? localStorage.getItem("language") : ""}></CustomSelector>
                        </div>
                        <Nav.Link className={"text-center d-flex align-items-center justify-content-center"}
                                  onClick={LogOut}>
                            <button
                                className={"btn btn-light  py-2 px-5"}>{pageTexts.btn[currentLanguage] ? pageTexts.btn[currentLanguage] : pageTexts.btn["pt-br"]}</button>
                        </Nav.Link>
                    </Nav>
                </div>


                <Modal show={show} onHide={x => setShow(false)}>
                    <Modal.Header>
                        {pageTexts.changePassword[currentLanguage] ? pageTexts.changePassword[currentLanguage] : pageTexts.changePassword["pt-br"]}
                    </Modal.Header>
                    <Modal.Body>
                        <p>{pageTexts.changePasswordInformative[currentLanguage] ? pageTexts.changePasswordInformative[currentLanguage] : pageTexts.changePasswordInformative["pt-br"]}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <button onClick={x => setShow(false)}
                                className='btn btn-danger'>{pageTexts.cancel[currentLanguage] ? pageTexts.cancel[currentLanguage] : pageTexts.cancel["pt-br"]}</button>
                        <button
                            className='btn btn-success' onClick={(e) => {
                            sendChangePass(e)
                        }}>{pageTexts.btnModal[currentLanguage] ? pageTexts.btnModal[currentLanguage] : pageTexts.btnModal["pt-br"]}</button>
                    </Modal.Footer>
                </Modal>
            </Navbar> :
            <Navbar className="bg-dark" expand="lg">
                <div className="container">
                    <Navbar.Brand>
                        <img src={logo} className="img-fluid imgLogoHeader" alt="Logo"/>
                    </Navbar.Brand>

                    <Navbar.Toggle style={{filter: "invert(1)"}} className="navbar-light"
                                   aria-controls="basic-navbar-nav"/>

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto align-items-center">
                            <Nav.Link className="text-center d-flex align-items-center justify-content-center">
                                <Link
                                    className={`${
                                        window.location.pathname === "/home"
                                            ? "text-info"
                                            : "text-light"
                                    }`}
                                    to="/home"
                                >
                                    {pageTexts.active[currentLanguage]
                                        ? pageTexts.active[currentLanguage]
                                        : pageTexts.active["pt-br"]}
                                </Link>
                            </Nav.Link>
                            <Nav.Link className="text-center d-flex align-items-center justify-content-center">
                                <Link
                                    className={`${
                                        window.location.pathname === "/fechadas"
                                            ? "text-info"
                                            : "text-light"
                                    }`}
                                    to="/fechadas"
                                >
                                    {pageTexts.finished[currentLanguage]
                                        ? pageTexts.finished[currentLanguage]
                                        : pageTexts.finished["pt-br"]}
                                </Link>
                            </Nav.Link>
                            <Nav.Link
                                className={"text-center d-flex align-items-center justify-content-center text-light"}
                                onClick={x => {
                                    setShow(true)
                                }}>{pageTexts.changePassword[currentLanguage] ? pageTexts.changePassword[currentLanguage] : pageTexts.changePassword["pt-br"]}</Nav.Link>
                            <div className="position-relative" style={{width: "200px"}}>
                                <CustomSelector
                                    label={"Idioma/Language"}
                                    light={true}
                                    items={[
                                        {label: "Português", img: brazilImg, value: "pt-br"},
                                        {label: "English", img: usaImg, value: "en"},
                                        {label: "Español", img: spainImg, value: "es"},
                                    ]}
                                    stateCallback={setLanguage}
                                    initialValue={
                                        localStorage.getItem("language")
                                            ? localStorage.getItem("language")
                                            : ""
                                    }
                                ></CustomSelector>
                            </div>
                            <Nav.Link
                                className="text-center d-flex align-items-center justify-content-center"
                                onClick={LogOut}
                            >
                                <button className="btn btn-light py-2 px-5">
                                    {pageTexts.btn[currentLanguage]
                                        ? pageTexts.btn[currentLanguage]
                                        : pageTexts.btn["pt-br"]}
                                </button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </div>

                <Modal show={show} onHide={x => setShow(false)}>
                    <Modal.Header>
                        {pageTexts.changePassword[currentLanguage] ? pageTexts.changePassword[currentLanguage] : pageTexts.changePassword["pt-br"]}
                    </Modal.Header>
                    <Modal.Body>
                        <p>{pageTexts.changePasswordInformative[currentLanguage] ? pageTexts.changePasswordInformative[currentLanguage] : pageTexts.changePasswordInformative["pt-br"]}</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <button onClick={x => setShow(false)}
                                className='btn btn-danger'>{pageTexts.cancel[currentLanguage] ? pageTexts.cancel[currentLanguage] : pageTexts.cancel["pt-br"]}</button>
                        <button
                            className='btn btn-success' onClick={(e) => {
                            sendChangePass(e)
                        }}>{pageTexts.btnModal[currentLanguage] ? pageTexts.btnModal[currentLanguage] : pageTexts.btnModal["pt-br"]}</button>
                    </Modal.Footer>
                </Modal>
            </Navbar>
    );
}

export default NavBarMine
