import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

const pageTexts = {
    notFound: {
        "es": "No encontrado...",
        "pt-br": "Não encontrado...",
        "en": "Not found..."
    },
    goHome: {
        "es": "Ir a la página de inicio",
        "pt-br": "Ir para a Página Inicial",
        "en": "Go to Home Page"
    },
    goLogin: {
        "es": "Ir a la página Login",
        "pt-br": "Ir para a Página de Login",
        "en": "Go to Login Page"
    },
};

function NotFoundPage() {
    const navigate = useNavigate();
    const [currentLanguage, setCurrentLanguage] = useState('');
    const [signed, setSigned] = useState(false);

    function defineCurrentLanguage() {

        setCurrentLanguage(localStorage.getItem("language"))
        setTimeout(defineCurrentLanguage, 100)
    }

    useEffect(() => {
        if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined) {
            setSigned(false)
        } else setSigned(true)
    }, [])

    useEffect(defineCurrentLanguage, []);

    return (
        <div className={'tela d-flex flex-column gap-5 align-items-center justify-content-center'}>
            <h1>{pageTexts.notFound[currentLanguage] ? pageTexts.notFound[currentLanguage] : pageTexts.notFound["pt-br"]}</h1>
            {
                signed ?
                    <div className={"d-flex flex-column gap-3"}>
                        <Link to='/home'
                              className={"btn btn-primary text-light py-3 px-5"}>{pageTexts.goHome[currentLanguage] ? pageTexts.goHome[currentLanguage] : pageTexts.goHome["pt-br"]}</Link>
                        <button onClick={() => {
                            localStorage.clear();
                            navigate("/")
                        }}
                                className={"btn btn-danger text-light py-3 px-5"}>{pageTexts.goLogin[currentLanguage] ? pageTexts.goLogin[currentLanguage] : pageTexts.goLogin["pt-br"]}</button>
                    </div> :
                    <div className={"d-flex flex-column gap-3"}>
                        <Link to='/'
                              className={"btn btn-primary text-light py-3 px-5"}>{pageTexts.goLogin[currentLanguage] ? pageTexts.goLogin[currentLanguage] : pageTexts.goLogin["pt-br"]}</Link>
                    </div>
            }
        </div>
    )
}

export default NotFoundPage;