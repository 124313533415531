import {useEffect, useState} from "react";
import arrowDown from '../../Images/Icons/Vector.png';
import "./customSelector.css";

const CustomSelector = (props) => {
    const [selectorValue, setValue] = useState(props.initialValue);
    const [label, setLabel] = useState(props.label);
    const [image, setImage] = useState(null);
    const [listId, setListId] = useState("")

    useEffect(() => {
        setListId("list-" + Math.floor(Math.random() * 999999999999))
        let item = props.items.find((x) => x.value === props.initialValue)
        if (item){
            setLabel(item.label);
            setImage(item.img);
        }
    }, [])

    useEffect(() => {
        props.stateCallback(selectorValue)
    }, [selectorValue])

    return (
        <div className={"selector fw-medium" + props.fontSize ? "fs-" + props.fontSize : ""}>
            <div className={"selectField"} onClick={(ev) => {
                document.getElementById(listId).classList.toggle("hide");
            }}>
                <div className={"selectField-labelimg"}>
                    <p className={props.light ? "text-light" : ""}>{label}</p>
                    {
                        image !== null ?
                            <img src={image} alt=""/> : ""
                    }
                </div>
                <img style={{filter: props.light ? "invert(1)" : ""}} src={arrowDown}/>
            </div>
            <ul id={listId} className={"list hide fw-normal"}>
                {
                    props.items.map((item, key) => {
                        return (
                            <li key={key} className="options" onClick={(ev) => {
                                setLabel(item.label)
                                setImage(item.img)
                                setValue(item.value)
                                document.getElementById(listId).classList.toggle("hide");
                            }}>
                                <span>{item.label}</span>
                                <img src={item.img} alt=""/>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
}

export default CustomSelector;