import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import ReportPage from "./Pages/ReportPage";
import VotingPage from "./Pages/VotingPage";
import NotFoundPage from "./Pages/NotFoundPage";
import RecoverPasswordPage from "./Pages/RecoverPassword";
import {history} from "./history";
import InsertRecoveryCode from "./Pages/InsertRecoveryCode";

function AppRouter() {
    return(
        <BrowserRouter history={history}>
            <Routes>
                <Route path={"/"} element={<LoginPage />}></Route>
                <Route path={"/home"} element={<VotingPage />}></Route>
                <Route path={"/fechadas"} element={<ReportPage />}></Route>
                <Route path={"/not-found"} element={<NotFoundPage />}></Route>
                <Route path={"/recover-password"} element={<RecoverPasswordPage />}></Route>
                <Route path={"/validate-recover-code"} element={<InsertRecoveryCode />}></Route>
                <Route path={"*"} element={<NotFoundPage />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;