import React, {useEffect, useState} from 'react';
import api from '../Services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import '../App.css'
import {Button} from 'react-bootstrap';
import NavBarMine from '../Components/NavBarMine';
import nullCountryImg from '../Images/Countries/null_country.png';

import circle from "../Images/Icons/circle.svg"
import greenCircle from "../Images/Icons/green-circle.svg"
import circleCheck from "../Images/Icons/circle-check.svg"
import circleMinus from "../Images/Icons/circle-minus.svg"
import clock from "../Images/Icons/clock.svg"

import '../App.css'
import {useNavigate} from "react-router-dom";

const pageTexts = {
    title: {
        "es": "Elección",
        "pt-br": "Eleição",
        "en": "Election"
    },
    noEllectionMessage: {
        "es": "No hay una elección activa en este momento, esperar...",
        "pt-br": "Não há nenhuma eleição ativa no momento, aguarde...",
        "en": "There is no active election at the moment, await..."
    },
    waitMessage: {
        "es": "Espere hasta que comience la votación para un puesto",
        "pt-br": "Aguarde até o início da votação de um cargo...",
        "en": "Wait until the start of a office voting..."
    },
    offices: {
        "es": "Subelecciones",
        "pt-br": "Subeleições",
        "en": "Subelections"
    },
    voteBtn: {
        "es": "Votar",
        "pt-br": "Votar",
        "en": "Vote"
    },
    candidatesNumber: {
        "es": "Seleccione X candidatos",
        "pt-br": "Selecione X candidatos",
        "en": "Select X candidates"
    },
    errorTitle: {
        "es": "Error",
        "pt-br": "Erro",
        "en": "Error"
    },
    candidatesErrorContent: {
        "es": "El número de candidatos seleccionados excede el límite de la posición (X). Revisa tus votos e inténtalo de nuevo.\n",
        "pt-br": "Número de candidatos selecionados excede o limite do cargo (X). Revise seus votos e tente novamente.",
        "en": "Number of selected candidates exceeds the office limit (X). Check your votes  and try again"
    },
    close: {
        "es": "Volver",
        "pt-br": "Voltar",
        "en": "Check"
    },
    confirmVote: {
        "es": "Revisa tus votos. Subelección:",
        "pt-br": "Confira seus votos. Subeleição: ",
        "en": "Check your votes. Subelection: "
    },
    save: {
        "es": 'Confirmar Votos',
        "pt-br": "Confirmar Votos",
        "en": "Confirm Votes"
    },
    null: {
        "es": 'Nulo',
        "pt-br": "Nulo",
        "en": "Null"
    },
    votedMessage: {
        "es": "¡Su país ha votado con éxito por este puesto! Si hay más puestos para votar, espera...",
        "pt-br": "Seu país votou com sucesso para este cargo! Caso hajam mais cargos para votar, aguarde...",
        "en": "Your country has successfully voted for this office! If there are more offices to vote, wait..."
    },
    candidates: {
        "es": "Candidatos",
        "pt-br": "Candidatos",
        "en": "Candidates"
    },
    await: {
        "es": "Esperar...",
        "pt-br": "Aguarde...",
        "en": "Await..."
    },
    voted: {
        "es": "Votado",
        "pt-br": "Votou",
        "en": "Voted"
    }
}

function VotingPage() {
    const navigate = useNavigate();

    const [voting, setVoting] = useState(false)

    const [currentLanguage, setCurrentLanguage] = useState('');
    const [countriesActivity, setCountriesActivity] = useState([]);
    const [validEllection, setValidEllection] = useState(false);
    const [ellection, setEllection] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [candidatesVote, setCandidatesVote] = useState([]);

    const [invalidCandidatesModalShow, setInvalidCandidatesModalShow] = useState(false);
    const [confirmVoteModalShow, setConfirmVoteModalShow] = useState(false);
    const handleInvalidCandidatesModalHide = () => {
        setInvalidCandidatesModalShow(false);
    }
    const handleConfirmVoteModalHide = () => {
        setConfirmVoteModalShow(false);
    }

    function defineCurrentLanguage() {
        setCurrentLanguage(localStorage.getItem("language"))
        setTimeout(defineCurrentLanguage, 100)
    }

    useEffect(() => {
        if (ellection !== null) {
            if (ellection.items.find((x) => x.ativo && !x.jaVotei)) {
                setCurrentItem(ellection.items.find((x) => x.ativo && !x.jaVotei))
            } else setCurrentItem(null)
        }
    }, [ellection])

    function GetActivity() {
        api.get("activity/get-activity", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
            setCountriesActivity(response.data)
        })
        setTimeout(() => {
            GetActivity()
        }, 5000)
    }

    function getEllection() {
        api.get("sessions/get-active-session", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
            setValidEllection(true);
            setEllection(response.data);
        }).catch(() => {
            setValidEllection(false);
            setEllection(null);
        })

        setTimeout(() => {
            getEllection()
        }, 3000);
    }

    function checkVotacao(ev) {
        ev.preventDefault()
        let candidatosCount = 0;
        let candidatosArray = [];
        currentItem.candidatos.forEach((candidato) => {
            const el = document.getElementById(currentItem.id + "-" + candidato.id);
            if (el.checked) {
                candidatosArray.push(candidato);
                candidatosCount++;
            }
        })
        if (candidatosCount > currentItem.cargo.qtdCadeiras) {
            setInvalidCandidatesModalShow(true);
        } else {
            let candidatesReturn = [];
            for (var i = 0; i < currentItem.cargo.qtdCadeiras; i++) {
                if (!candidatosArray[i]) {
                    candidatesReturn.push(null)
                } else {
                    candidatesReturn.push(candidatosArray[i]);
                }
            }
            candidatesReturn.sort((a, b) => {
                if (a !== null && b === null) {
                    return -1;
                } else if (b === null && a !== null) {
                    return 1;
                } else return 0;
            })

            setCandidatesVote(candidatesReturn);
            setConfirmVoteModalShow(true);
        }
    }

    async function sendVotes() {
        setVoting(true);
        let voteBody = [];
        candidatesVote.forEach((candidate) => {
            if (candidate !== null) {
                voteBody.push({
                    candidatoId: candidate.id
                });
            } else {
                voteBody.push({
                    candidatoId: null
                });
            }
        })
        handleConfirmVoteModalHide();
        await api.post('sessions/vote-by-item-id/' + currentItem.id, voteBody, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
        }).catch((error) => {
            console.log(error)
        })
        setVoting(false);
    }

    function SendActivity() {
        api.post("activity/send-activity", {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
        }).catch(error => {
            window.location.reload();
        })

        setTimeout(() => {
            SendActivity()
        }, 4000);
    }

    useEffect(() => {
        if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined) {
            window.localStorage.clear();
            navigate("/")
        } else {
            defineCurrentLanguage()
            SendActivity()
            GetActivity()
            getEllection()
        }
    }, [])


    return (
        <div className='tela'>
            <NavBarMine/>
            <div className='container text-center d-flex flex-column align-items-center w-100'>
                <div className="d-flex flex-wrap gap-4 align-items-center justify-content-center mt-5">
                    {
                        countriesActivity.map((country, key) => {
                            if (country.atividade === "inativo") {
                                return (
                                    <OverlayTrigger
                                        key={key}
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip id={"tooltip-" + country.id}>
                                                <p>{country.nome} - {country.sigla}</p>
                                                <strong>Offline</strong>
                                            </Tooltip>
                                        }>
                                        <img key={key} style={{
                                            width: "60px",
                                            height: "36px",
                                            objectFit: "cover",
                                            filter: "grayscale(1)"
                                        }} src={"data:image/png;base64," + country.imagem} alt={country.nome}/>
                                    </OverlayTrigger>
                                )
                            } else if (country.atividade === "ativo") {
                                return (
                                    <OverlayTrigger
                                        key={key}
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip id={"tooltip-" + country.id}>
                                                <p>{country.nome} - {country.sigla}</p>
                                                <strong>Online</strong>
                                            </Tooltip>
                                        }>
                                        <img key={key} style={{
                                            width: "60px",
                                            height: "36px",
                                            objectFit: "cover"
                                        }} src={"data:image/png;base64," + country.imagem} alt={country.nome}/>
                                    </OverlayTrigger>
                                )
                            } else {
                                return (
                                    <OverlayTrigger
                                        key={key}
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip id={"tooltip-" + country.id}>
                                                <p>{country.nome} - {country.sigla}</p>
                                                <strong>{pageTexts.voted[currentLanguage] ? pageTexts.voted[currentLanguage] : pageTexts.voted["pt-br"]}</strong>
                                            </Tooltip>
                                        }>
                                        <img key={key} style={{
                                            width: "60px",
                                            height: "36px",
                                            objectFit: "cover",
                                            boxShadow: "0 0 10px #39FF14",
                                            border: "1px solid #39FF14"
                                        }} src={"data:image/png;base64," + country.imagem} alt={country.nome}/>
                                    </OverlayTrigger>
                                )
                            }
                        })
                    }
                </div>
                <h1 className='my-5'>
                    <p className="text-primary fs-6 fw-normal">{validEllection ? ellection.titulo : ""}</p>{pageTexts.title[currentLanguage] ? pageTexts.title[currentLanguage] : pageTexts.title["pt-br"]}
                </h1>
                {
                    !validEllection ?
                        <div
                            className="my-5 py-5 w-100 d-flex align-items-center justify-content-center gap-3 flex-column">
                            <img style={{width: "10%"}} src={clock} alt=""/>
                            <p className="text-danger fs-6 fw-bold">{pageTexts.noEllectionMessage[currentLanguage] ? pageTexts.noEllectionMessage[currentLanguage] : pageTexts.noEllectionMessage["pt-br"]}</p>
                        </div> :
                        <div
                            className={"d-flex justify-content-between w-100 align-items-start gap-5" + (window.innerWidth >= 768 ? "" : " flex-column-reverse align-items-center gap-5")}>
                            <div className="d-flex flex-column align-items-start w-50">
                                <h2 className="mb-3">{pageTexts.offices[currentLanguage] ? pageTexts.offices[currentLanguage] : pageTexts.offices["pt-br"]}</h2>
                                {
                                    ellection.items.map((item, index) => {
                                        return (
                                            <div key={index}
                                                 className="d-flex align-items-start justify-content-start flex-column">
                                                <div className="d-flex gap-3 align-items-center">
                                                    {
                                                        (!item.ativo && !item.fechado) ?
                                                            <img style={{width: "30px", height: "30px"}} src={circle}
                                                                 alt=""/> : (item.ativo && !item.jaVotei) ?
                                                                <img style={{width: "30px", height: "30px"}}
                                                                     src={greenCircle}
                                                                     alt=""/> : (item.ativo && item.jaVotei) ?
                                                                    <img style={{width: "30px", height: "30px"}}
                                                                         src={circleCheck}
                                                                         alt=""/> : (item.fechado && item.jaVotei) ?
                                                                        <img style={{width: "30px", height: "30px"}}
                                                                             src={circleCheck}
                                                                             alt=""/> : (item.fechado && !item.jaVotei) ?
                                                                            <img style={{width: "30px", height: "30px"}}
                                                                                 src={circleMinus} alt=""/> : ""
                                                    }
                                                    <span className={"fs-4"}>{" - " + item.titulo + " - "}</span>
                                                </div>
                                                {
                                                    index < ellection.items.length - 1 &&
                                                    <div className="my-2 bg-black" style={{
                                                        height: "35px",
                                                        width: "1px",
                                                        marginLeft: "15px"
                                                    }}></div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            {
                                !ellection.items.find((x) => x.ativo) ?
                                    <div className="d-flex flex-column align-items-center w-50">
                                        <p className="fs-5">{pageTexts.waitMessage[currentLanguage] ? pageTexts.waitMessage[currentLanguage] : pageTexts.waitMessage["pt-br"]}</p>
                                        <img src={clock} style={{width: "40px"}} alt=""/>
                                    </div> : ellection.items.find((x) => x.ativo && !x.jaVotei) ?
                                        currentItem !== null &&
                                        <div className="d-flex flex-column align-items-start w-50">
                                            <h2>{currentItem.titulo}</h2>
                                            <span
                                                className="mb-3 text-muted">{pageTexts.candidatesNumber[currentLanguage] ? pageTexts.candidatesNumber[currentLanguage].replace("X", currentItem.cargo.qtdCadeiras) : pageTexts.candidatesNumber["pt-br"].replace("X", currentItem.cargo.qtdCadeiras)}</span>
                                            <form onSubmit={(ev) => checkVotacao(ev)} className="w-100">
                                                <div className="d-flex align-items-start gap-2 flex-column">
                                                    {
                                                        currentItem.candidatos.map((candidato, key) => {
                                                            return (
                                                                <label key={key}
                                                                       className="d-flex gap-3 fs-5 align-items-center">
                                                                    <input type="checkbox"
                                                                           id={currentItem.id + "-" + candidato.id}/>
                                                                    <img className="border border-black"
                                                                         src={"data:image/png;base64," + candidato.pais.imagem}
                                                                         style={{width: "30px", height: "18px"}}
                                                                         alt={candidato.pais.nome}/>
                                                                    {candidato.pais.sigla + " - " + candidato.nome}
                                                                </label>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                {
                                                    voting ?
                                                        <button
                                                            disabled={true}
                                                            type={"submit"}
                                                            className="w-100 btn btn-primary mt-5">{pageTexts.await[currentLanguage] ? pageTexts.await[currentLanguage] : pageTexts.await["pt-br"]}</button>
                                                        :
                                                        <button
                                                            type={"submit"}
                                                            className="w-100 btn btn-primary mt-5">{pageTexts.voteBtn[currentLanguage] ? pageTexts.voteBtn[currentLanguage] : pageTexts.voteBtn["pt-br"]}</button>
                                                }
                                            </form>
                                        </div> : <div className="d-flex flex-column align-items-center w-50">
                                            <p className="fs-5">{pageTexts.votedMessage[currentLanguage] ? pageTexts.votedMessage[currentLanguage] : pageTexts.votedMessage["pt-br"]}</p>
                                            <img src={circleCheck} style={{width: "40px"}} alt=""/>
                                        </div>
                            }
                        </div>
                }
            </div>
            <Modal show={invalidCandidatesModalShow} onHide={handleInvalidCandidatesModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{pageTexts.errorTitle[currentLanguage] ? pageTexts.errorTitle[currentLanguage] : pageTexts.errorTitle["pt-br"]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        currentItem !== null &&
                        <p>{pageTexts.candidatesErrorContent[currentLanguage] ? pageTexts.candidatesErrorContent[currentLanguage].replace("X", currentItem.cargo.qtdCadeiras) : pageTexts.candidatesErrorContent["pt-br"].replace("X", currentItem.cargo.qtdCadeiras)}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleInvalidCandidatesModalHide}>
                        {pageTexts.close[currentLanguage] ? pageTexts.close[currentLanguage] : pageTexts.close["pt-br"]}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={confirmVoteModalShow} onHide={handleConfirmVoteModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{pageTexts.confirmVote[currentLanguage] ? pageTexts.confirmVote[currentLanguage] : pageTexts.confirmVote["pt-br"]} {currentItem !== null && currentItem.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>{pageTexts.candidates[currentLanguage] ? pageTexts.candidates[currentLanguage] : pageTexts.candidates["pt-br"]}</h5>
                    <ul>
                        {
                            candidatesVote.map((candidate, key) => {
                                if (candidate !== null) {
                                    return (
                                        <li className={"d-flex align-items-center gap-3"} key={key}><img
                                            className="border border-black"
                                            src={"data:image/png;base64," + candidate.pais.imagem}
                                            style={{width: "30px", height: "18px"}}
                                            alt={candidate.pais.nome}/>
                                            {candidate.pais.sigla + " - " + candidate.nome}</li>
                                    )
                                } else return <li className={"d-flex align-items-center gap-3 fw-bolder"} key={key}><img
                                    className="border border-black"
                                    src={nullCountryImg}
                                    style={{width: "30px", height: "18px"}}
                                    alt={pageTexts.null[currentLanguage] ? pageTexts.null[currentLanguage] : pageTexts.null["pt-br"]}/> {pageTexts.null[currentLanguage] ? pageTexts.null[currentLanguage] : pageTexts.null["pt-br"]}
                                </li>
                            })
                        }
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleConfirmVoteModalHide}>
                        {pageTexts.close[currentLanguage] ? pageTexts.close[currentLanguage] : pageTexts.close["pt-br"]}
                    </Button>
                    <Button variant="success" onClick={sendVotes}>
                        {pageTexts.save[currentLanguage] ? pageTexts.save[currentLanguage] : pageTexts.save["pt-br"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default VotingPage;