import React, {useEffect, useState} from 'react';
import api from '../Services/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css'
import NavBarMine from '../Components/NavBarMine';
import Modal from "react-bootstrap/Modal";
import nullCountryImg from "../Images/Countries/null_country.png";
import downloadImg from '../Images/download-btn.svg';
import {Button, Toast} from "react-bootstrap";
import ToastContainer from 'react-bootstrap/ToastContainer';


import {useNavigate} from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from 'react-bootstrap/Tooltip';


const pageTexts = {
    title: {
        "es": "Elecciones terminadas",
        "pt-br": "Eleições finalizadas",
        "en": "Finished ellections"
    },
    totalVotes: {
        "es": "Total de votos: ",
        "pt-br": "Total de votos: ",
        "en": "Total votes: "
    },
    votes: {
        "es": "votos",
        "pt-br": "votos",
        "en": "votes"
    },
    next: {
        "es": "Próximo",
        "pt-br": "Próximo",
        "en": "Next"
    },
    previous: {
        "es": "Anterior",
        "pt-br": "Anterior",
        "en": "Previous"
    },
    seeDetails: {
        "es": "Ver detalles",
        "pt-br": "Ver detalhes",
        "en": "See details"
    },
    close: {
        "es": "Volver",
        "pt-br": "Voltar",
        "en": "Go Back"
    },
    numberOfVotes: {
        "es": "Numero de Votos",
        "pt-br": "Número de Votos",
        "en": "Number of Votes"
    },
    null: {
        "es": 'Nulo',
        "pt-br": "Nulo",
        "en": "Null"
    },
    showGraph: {
        "es": 'Mostrar gráfico',
        "pt-br": "Mostrar Gráfico",
        "en": "Show Graph"
    }
};

function ReportPage() {
    const [hasActiveSession, setHasActiveSession] = useState(false)
    const navigate = useNavigate();

    const [currentLanguage, setCurrentLanguage] = useState('');
    const [countriesActivity, setCountriesActivity] = useState([]);

    const [page, setPage] = useState(1);
    const [pagesNum, setPagesNum] = useState(1);
    const [pagesBtns, setPagesBtns] = useState([]);
    const [closedEllections, setClosedEllections] = useState([]);
    const [detailsModal, setDetailsModal] = useState(false);
    const [detailsObj, setDetailsObj] = useState({});
    const [detailsOffices, setDetailsOffices] = useState([]);

    const [graphLabels, setGraphLabels] = useState([]);
    const [graphData, setGraphData] = useState([]);

    function getData() {
        api.get("sessions/get-closed-sessions?page=" + page, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
            setPagesNum(Math.ceil(response.data.itemsCount / response.data.pageSize))
            setClosedEllections(response.data.sessions)
        }).catch(error => {
            console.log(error)
        })
    }

    const handleDetailsModalHide = () => {
        setDetailsModal(false);
        setGraphData([]);
        setGraphLabels([]);
    }

    useEffect(() => {
        let tempBtnArray = [];
        for (var i = 0; i < pagesNum; i++) {
            tempBtnArray.push(i + 1);
        }
        setPagesBtns(tempBtnArray)
    }, [pagesNum])

    function checkActiveSession() {
        api.get("sessions/get-active-session", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
            setHasActiveSession(true)
        }).catch(() => {
            setHasActiveSession(false)
        })

        setTimeout(checkActiveSession, 3000)
    }

    function defineCurrentLanguage() {
        setCurrentLanguage(localStorage.getItem("language"))
        setTimeout(defineCurrentLanguage, 100)
    }

    function SendActivity() {
        api.post("activity/send-activity", {}, {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
        }).catch(error => {
            window.location.reload();
        })

        setTimeout(() => {
            SendActivity()
        }, 4000);
    }

    function GetActivity() {
        api.get("activity/get-activity", {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
            setCountriesActivity(response.data)
        })
        setTimeout(() => {
            GetActivity()
        }, 5000)
    }

    function donwloadReport(idSessao) {
        api.post("sessions/get-session-report/" + idSessao, {}, {
            responseType: 'blob',
            headers: {
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        }).then((response) => {
            const blobUrl = URL.createObjectURL(response.data);

            // Create a link element and simulate a click to trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = closedEllections.find(x => x.id === idSessao).titulo + '_report.pdf'; // Specify the default download filename
            link.click();
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        if (hasActiveSession) {
            setTimeout(() => {
                navigate("/home")
            }, 3000)
        }
    }, [hasActiveSession])

    useEffect(() => {
        getData();
    }, [page])

    useEffect(() => {
        if (detailsObj.items !== undefined) {
            let offices = [];
            for (const item of detailsObj.items) {
                if (!offices.find((x) => x.id === item.cargo.id)) {
                    let cargoData = item.cargo;
                    delete cargoData.qtdCadeiras;
                    offices.push(cargoData);
                }
            }
            setDetailsOffices(offices)
        }
    }, [detailsObj]);

    useEffect(() => {
        if (localStorage.getItem("token") === null || localStorage.getItem("token") === undefined) {
            window.localStorage.clear();
            navigate("/")
        } else {
            defineCurrentLanguage();
            SendActivity()
            GetActivity()
            checkActiveSession()
        }
    }, [])

    return (
        <div className='tela'>
            <NavBarMine/>
            {
                hasActiveSession &&
                <ToastContainer
                    className="p-3"
                    position={"top-end"}
                    style={{zIndex: 10000}}
                >
                    <Toast
                        variant="success"
                        bg={"warning"}
                        dismissible
                    >
                        <Toast.Header>
                            <strong>Aviso: </strong> Há uma votação em andamento.
                        </Toast.Header>
                        <Toast.Body>Você será redirecionado em breve...</Toast.Body>
                    </Toast>
                </ToastContainer>
            }
            <div className='container text-center d-flex flex-column align-items-center w-100'>
                <div className="d-flex flex-wrap gap-4 align-items-center justify-content-center mt-5">
                    {
                        countriesActivity.map((country, key) => {
                            if (country.atividade === "inativo") {
                                return (
                                    <OverlayTrigger
                                        key={key}
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip id={"tooltip-" + country.id}>
                                                <p>{country.nome} - {country.sigla}</p>
                                                <strong>Offline</strong>
                                            </Tooltip>
                                        }>
                                        <img key={key} style={{
                                            width: "60px",
                                            height: "36px",
                                            objectFit: "cover",
                                            filter: "grayscale(1)"
                                        }} src={"data:image/png;base64," + country.imagem} alt={country.nome}/>
                                    </OverlayTrigger>
                                )
                            } else if (country.atividade === "ativo") {
                                return (
                                    <OverlayTrigger
                                        key={key}
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip id={"tooltip-" + country.id}>
                                                <p>{country.nome} - {country.sigla}</p>
                                                <strong>Online</strong>
                                            </Tooltip>
                                        }>
                                        <img key={key} style={{
                                            width: "60px",
                                            height: "36px",
                                            objectFit: "cover"
                                        }} src={"data:image/png;base64," + country.imagem} alt={country.nome}/>
                                    </OverlayTrigger>
                                )
                            } else {
                                return (
                                    <OverlayTrigger
                                        key={key}
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip id={"tooltip-" + country.id}>
                                                <p>{country.nome} - {country.sigla}</p>
                                                <strong>{pageTexts.voted[currentLanguage] ? pageTexts.voted[currentLanguage] : pageTexts.voted["pt-br"]}</strong>
                                            </Tooltip>
                                        }>
                                        <img key={key} style={{
                                            width: "60px",
                                            height: "36px",
                                            objectFit: "cover",
                                            boxShadow: "0 0 10px #39FF14",
                                            border: "1px solid #39FF14"
                                        }} src={"data:image/png;base64," + country.imagem} alt={country.nome}/>
                                    </OverlayTrigger>
                                )
                            }
                        })
                    }
                </div>
                <h1 className='mt-5 mb-3'>{pageTexts.title[currentLanguage] ? pageTexts.title[currentLanguage] : pageTexts.title["pt-br"]}</h1>

                <div className="d-flex align-items-center justify-content-center w-100 gap-4 flex-wrap">
                    {
                        closedEllections.map((ellection) => {
                            return (
                                <div className="card w-50">
                                    <div className="card-body">
                                        <h5 className="card-title">{ellection.titulo}</h5>
                                        <p className="card-text">{ellection.descricao}</p>
                                        <button onClick={(e) => {
                                            setDetailsModal(true)
                                            setDetailsObj(ellection)
                                        }} href="#"
                                                className="btn btn-primary text-light">{pageTexts.seeDetails[currentLanguage] ? pageTexts.seeDetails[currentLanguage] : pageTexts.seeDetails["pt-br"]}</button>
                                    </div>
                                    <div className="card-footer">
                                        <div className={"d-flex align-items-end justify-content-end w-100 link-underline-primary text-decoration-underline"}>
                                            <button onClick={(ev) => {
                                                donwloadReport(ellection.id)
                                            }} className={"border-0 bg-transparent gap-3  d-flex align-items-center justify-content-center text-primary"}>
                                                Baixar relatório
                                                <img style={{width: "20px"}} src={downloadImg} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <nav aria-label="..." className="mt-5">
                    <ul className="pagination">
                        <li className={page > 1 ? "page-item" : "page-item disabled"}>
                            <button onClick={(e) => {
                                setPage(page - 1);
                            }} className="page-link"
                                    tabIndex="-1">{pageTexts.previous[currentLanguage] ? pageTexts.previous[currentLanguage] : pageTexts.previous["pt-br"]}
                            </button>
                        </li>
                        {
                            pagesBtns.map((btn) => {
                                if (btn === page) {
                                    return (
                                        <li className="page-item active">
                                            <button className="page-link">{btn}</button>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li className="page-item">
                                            <button onClick={(e) => {
                                                setPage(btn)
                                            }} className="page-link">{btn}</button>
                                        </li>
                                    )
                                }
                            })
                        }
                        <li className={page < pagesNum ? "page-item" : "page-item disabled"}>
                            <button onClick={(e) => {
                                setPage(page + 1);
                            }}
                                    className="page-link">{pageTexts.next[currentLanguage] ? pageTexts.next[currentLanguage] : pageTexts.next["pt-br"]}
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <Modal show={detailsModal} onHide={handleDetailsModalHide} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{detailsObj.titulo && detailsObj.titulo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"d-flex w-100 flex-column align-items-center"}>
                        <p className={"fs-5 mb-5 w-100 text-left"}>{detailsObj.descricao && detailsObj.descricao}</p>
                        {
                            detailsOffices &&
                            detailsOffices.map((office) => {
                                return (
                                    <div className={"d-flex flex-column align-items-start w-100 gap-3 mb-5"}>
                                        <h4>{office.titulo}</h4>
                                        {
                                            <div className={"d-flex flex-wrap gap-5"}>
                                                {
                                                    detailsObj.items &&
                                                    detailsObj.items.filter((item) => item.cargo.id === office.id).map((item, index) => {
                                                        return (
                                                            <div className={"card"} style={{width: "650px"}}>
                                                                <div className={"card-header"}>
                                                                    <h5>{item.titulo}</h5>
                                                                </div>
                                                                <div
                                                                    className="card-body">
                                                                    <table
                                                                        className="table table-striped table-bordered">
                                                                        <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Bandeira</th>
                                                                            <th scope="col">Sigla</th>
                                                                            <th scope="col">Nome</th>
                                                                            <th scope="col">Votos(#)</th>
                                                                            <th scope="col">Votos(%)</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                            item.candidatos && item.candidatos.map((candidato, index) => {
                                                                                let votesPercent = (0).toFixed(2);
                                                                                if (candidato.votos > 0 && item.votosTotais > 0) {
                                                                                    votesPercent = ((candidato.votos * 100) / item.votosTotais).toFixed(2);
                                                                                }
                                                                                return (
                                                                                    <tr>
                                                                                        <th scope="row">{index + 1}</th>
                                                                                        <td>{candidato.pais ?
                                                                                            <img
                                                                                                className="border border-black"
                                                                                                src={"data:image/png;base64," + candidato.pais.imagem}
                                                                                                style={{
                                                                                                    width: "30px",
                                                                                                    height: "18px"
                                                                                                }}
                                                                                                alt={candidato.pais.nome}/> :
                                                                                            <img
                                                                                                className="border border-black"
                                                                                                src={nullCountryImg}
                                                                                                style={{
                                                                                                    width: "30px",
                                                                                                    height: "18px"
                                                                                                }}
                                                                                                alt={pageTexts.null[currentLanguage] ? pageTexts.null[currentLanguage] : pageTexts.null["pt-br"]}/>
                                                                                        }</td>
                                                                                        <td>{candidato.pais ? candidato.pais.sigla : "N/A"}</td>
                                                                                        <td>{candidato.nome}</td>
                                                                                        <td>{candidato.votos}</td>
                                                                                        <td>{votesPercent + "%"}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                        </tbody>
                                                                    </table>
                                                                    <h6 className="fw-bold">{pageTexts.totalVotes[currentLanguage] ? pageTexts.totalVotes[currentLanguage] : pageTexts.totalVotes["pt-br"]}
                                                                        <span
                                                                            className="fw-normal">{item.votosTotais}</span>
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDetailsModalHide}>
                        {pageTexts.close[currentLanguage] ? pageTexts.close[currentLanguage] : pageTexts.close["pt-br"]}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ReportPage;